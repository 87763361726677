import React from 'react'

import { 
  PDFDownloadLink, 
  Document, 
  Page, 
  View, 
  Text, 
  StyleSheet 
} from '@react-pdf/renderer';

import { Button } from 'rbx'

// Create styles
const styles = StyleSheet.create({
  body: {
    padding: 10
  },
  table: { 
    display: "table", 
    width: "auto", 
    borderStyle: "solid", 
    borderColor: '#bfbfbf',
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0 
  }, 
  tableRow: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 
  tableColHeader: { 
    width: "25%", 
    borderStyle: "solid", 
    borderColor: '#bfbfbf',
    borderBottomColor: '#000',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },   
  tableCol: { 
    width: "25%", 
    borderStyle: "solid", 
    borderColor: '#bfbfbf',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableCellHeader: {
    margin: "auto", 
    margin: 5, 
    fontSize: 12,
    fontWeight: 500
  },  
  tableCell: { 
    margin: "auto", 
    margin: 5, 
    fontSize: 10 
  }
});

const MyDoc = ({ title, attendees }) => (
  <Document>
    <Page  size="A4" style={styles.body}>
      <View>
      	<Text>{ title }</Text>
        <Text> </Text>
      </View>
      <View style={styles.table}> 
        <View style={styles.tableRow}> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>#</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Nombre</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Edad</Text> 
          </View> 
          <View style={styles.tableColHeader}> 
            <Text style={styles.tableCellHeader}>Familia</Text> 
          </View> 
        </View>
        {
            attendees.map( ( att, i ) => (
              <View style={styles.tableRow} key={ i }> 
                <View style={styles.tableCol}> 
                  <Text style={styles.tableCell}>{ i + 1 }</Text> 
                </View> 
                <View style={styles.tableCol}> 
                  <Text style={styles.tableCell}>{ att.fullname }</Text> 
                </View> 
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{ att.age }</Text> 
                </View>
                <View style={styles.tableCol}> 
                  <Text style={styles.tableCell}>{ att.family }</Text> 
                </View> 
              </View> 
            ))
        }
            
      </View>
    </Page>
  </Document>
);


const DownloadTable = ({ title, attendees }) => (
  <div  style={{ marginBottom: "1.5rem" }} >
    <PDFDownloadLink document={<MyDoc title={ title } attendees={ attendees } />} fileName={`${ title.toLowerCase().replace(" ", "")}.pdf`} >
      <Button color="primary" >Descargar</Button>
    </PDFDownloadLink>
  </div>
)
export default DownloadTable
