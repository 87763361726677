import React, { Fragment } from "react";

import "rbx/index.css";
import "./App.css"
import { Navbar, Container, Block } from "rbx";

function App({ children }) {
  return (
    <Fragment>
      <Navbar color="black" >
        <Navbar.Brand>
          <Navbar.Item href="/">
            <img
              src="/iasd.png"
              alt=""
            />
          </Navbar.Item>
          <Navbar.Burger />
        </Navbar.Brand>
        <Navbar.Menu>
          <Navbar.Segment align="start">
            <Navbar.Item>Registro de Asistencia</Navbar.Item>
          </Navbar.Segment>
        </Navbar.Menu>
      </Navbar>

      <Block />
      
      <Container >
    
      { children }

      </Container>

    </Fragment>
  );
}

export default App;
