import React, { useState, useEffect, Fragment, useMemo } from 'react'

import TablaAsistentes from './TablaAsistentes'

import { Box, Column, Tab } from 'rbx'
import feathers from "../feathers-client"
import moment from "moment";

const Lista = () => {

  const [ attendees, setAttendees ] = useState({ "culto-divino": [], "culto-joven" : [] })
  const [ currentDate ] = useState(() => moment().day( 6 ).format( "DD/MM/YYYY" ))
  const [ tabActive, setTabActive ] = useState("viernes")
  const attendeesClient = useMemo(() => feathers.service( 'attendees' ), [])

  useEffect(() => {
    getAttendees()
  }, [])

  const sortLista = ( a, b ) => {
    if ( 
      a.family.replace(".", "").normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().trim() 
      > 
      b.family.replace(".", "").normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().trim() 
      ) {
      return 1;
    }
    if (
      a.family.replace(".", "").normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().trim() 
      < 
      b.family.replace(".", "").normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().trim()
      ) {
      return -1;
    }
    // a must be equal to b
    return 0;
  }

  const getAttendees = () => {
    attendeesClient.find({
      query: {
        date: currentDate
      }
    })
    .then( res => {
      const VIERNES = res.filter( a => a.program === "viernes" )
      const MANANA = res.filter( a => a.program === "sabado-manana" )
      const TARDE = res.filter( a => a.program === "sabado-tarde" )
      const VIERNES_arr = VIERNES.map(( att, index ) => {
        const reg = /(\W|^)(Fam.|Fam|fam.|fam|familia|Familia)(\W|$)/
        return { 
          ...att, idx: index + 1,
          family: att.family.replace(reg, "")
        }
      })
      const MANANA_arr = MANANA.map(( att, index ) => {
        const reg = /(\W|^)(Fam.|Fam|fam.|fam|familia|Familia)(\W|$)/
        return { 
          ...att, idx: index + 1,
          family: att.family.replace(reg, "")
        }
      })
      const TARDE_arr = TARDE.map(( att, index ) => {
        const reg = /(\W|^)(Fam.|Fam|fam.|fam|familia|Familia)(\W|$)/
        return { 
          ...att, idx: index + 1,
          family: att.family.replace(reg, "")
        }
      })
      // setAttendees({ "culto-divino": CD.sort( sortLista ), "culto-joven": CJ.sort( sortLista ) })
      setAttendees({ "viernes": VIERNES_arr, "sabado-manana": MANANA_arr, "sabado-tarde": TARDE_arr })
    })
    .catch( err => console.log( "ERR get attendees =>", err ))
  }

  const handleClickCheckbox = ( id, checked, program ) => {
    console.log("program =>", program)
    const newChecked = checked === "1" ? "0" : "1"
    attendeesClient.patch(id, { checked: newChecked })
    const att_temp = [ ...attendees[ program ] ]
    const idx = att_temp.findIndex( a => a._id === id )
    att_temp[idx] = {
      ...att_temp[idx],
      checked: newChecked
    }
    setAttendees({
      ...attendees,
      [program]: att_temp
    })
  }

  return (
    <Fragment>
      {/* TABS */}
      <Tab.Group>
        <Tab active={ tabActive === "viernes" } onClick={ () => setTabActive("viernes") } >Viernes Culto de Consagración</Tab>
        <Tab active={ tabActive === "sabado-manana" } onClick={ () => setTabActive("sabado-manana") }>Culto Divino</Tab>
        <Tab active={ tabActive === "sabado-tarde" } onClick={ () => setTabActive("sabado-tarde") }>Culto Joven</Tab>
      </Tab.Group>

      {/* TABLES */}
      <Column.Group multiline>
        {
          tabActive === "viernes" && 
          <Column size={ 12 }>
            <Box>
              <TablaAsistentes title="Viernes Culto de Consagración" attendees={ attendees["viernes"] } handleClickCheckbox={ handleClickCheckbox } />
            </Box>
          </Column>
        }
        
        {
          tabActive === "sabado-manana" && 
          <Column size={ 12 }>
            <Box>
              <TablaAsistentes title="Culto Divino" attendees={ attendees["sabado-manana"] } handleClickCheckbox={ handleClickCheckbox } />
            </Box>
          </Column>
        }

{
          tabActive === "sabado-tarde" && 
          <Column size={ 12 }>
            <Box>
              <TablaAsistentes title="Culto Joven" attendees={ attendees["sabado-tarde"] } handleClickCheckbox={ handleClickCheckbox } />
            </Box>
          </Column>
        }
        
      </Column.Group>
      

      
    </Fragment>
  )

}

export default Lista
