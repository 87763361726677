import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
// import { GeneralProvider } from "./Context/GeneralContext"

import App from './Components/App'
import Lista from './Components/Lista'
import Registro from './Components/Registro'

const AppRoutes = () => (
//   <GeneralProvider>
    <App>
      <BrowserRouter>
        <Switch>
          <Route exact path="/lista" component={ Lista } />
          <Route exact path="/" component={ Registro } />
        </Switch>
      </BrowserRouter>
    </App>
//   </GeneralProvider>
)

export default AppRoutes;